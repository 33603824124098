import request from '@/utils/request';

const PATH = '/nice';

export const getEncData = (params: any) => request({
  url: PATH,
  method: 'get',
  params,
});

export const getInfo = (encodeData: any) => request({
  url: `${PATH}/info`,
  method: 'get',
  params: {
    encData: encodeData,
  },
});
