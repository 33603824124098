
import { parseConcatNumber } from '@/filters';
import { ElForm } from 'element-ui/types/form';
import { Component, Vue } from 'vue-property-decorator';
import { joinUser } from '@/api/user';
import { getEncData, getInfo } from '@/api/nice';
import moment from 'moment';
import {
  getLoginCode,
  getLoginType,
  setLoginCode,
  setLoginType,
} from '@/utils/cookies';

@Component({
  name: 'LoginPage',
  components: {
  },
})

export default class extends Vue {
  mounted() {
    this.getEncData();
    if (this.$route.query.type) setLoginType(this.$route.query.type);
    if (this.$route.query.code) setLoginCode(this.$route.query.code);
    if (this.$route.query.EncodeData) {
      this.setUserInfo();
      if (getLoginType() === 'apple') this.appleFlag = true;
    }
    if (this.$route.query.type === 'apple') this.appleFlag = true;
  }

  private encData: any = '';

  private appleFlag = false;

  private form: any = {
    actualName: '',
    concatNumber: '',
    birth: '',
    email: '',
    type: '',
    code: '',
    region: '',
  }

  private validateConcatNumber = (rule:any, value:any, callback:any) => {
    const regPhone = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/;
    const regPhone2 = /^(0(2|3[1-3]|4[1-4]|5[1-5]|6[1-4]))-(\d{3,4})-(\d{4})$/;
    if (!regPhone.test(value) && !regPhone2.test(value)) {
      callback(new Error('연락처 형식에 맞지 않습니다.'));
    } else {
      callback();
    }
  };

  private regionList = [
    { value: '11', label: '서울특별시' },
    { value: '21', label: '부산광역시' },
    { value: '22', label: '대구광역시' },
    { value: '23', label: '인천광역시' },
    { value: '24', label: '광주광역시' },
    { value: '25', label: '대전광역시' },
    { value: '26', label: '울산광역시' },
    { value: '29', label: '세종특별자치시' },
    { value: '31', label: '경기도' },
    { value: '32', label: '강원도' },
    { value: '33', label: '충청북도' },
    { value: '34', label: '충청남도' },
    { value: '35', label: '전라북도' },
    { value: '36', label: '전라남도' },
    { value: '37', label: '경상북도' },
    { value: '38', label: '경상남도' },
    { value: '39', label: '제주특별자치도' },
  ]

  private rules = {
    actualName: [
      { required: true, message: '성명을 입력하세요.', trigger: ['change', 'blur'] },
    ],
    concatNumber: [
      { required: true, message: '연락처를 입력하세요.', trigger: ['change', 'blur'] },
      { validator: this.validateConcatNumber, trigger: ['change', 'blur'] },
    ],
    birth: [
      { required: true, message: '생년월일을 입력하세요.', trigger: ['change', 'blur'] },
    ],
    region: [
      { required: true, message: '거주지역을 입력하세요.', trigger: ['change', 'blur'] },
    ],
    email: [
      { type: 'email', message: '올바른 이메일 형식으로 입력하세요.', trigger: ['change', 'blur'] },
    ],
  }

  private getEncData() {
    getEncData({ redirectUrl: '/join', type: this.$route.query.type }).then((res) => {
      this.encData = res.data;
    });
  }

  private filterConcatNumber() {
    this.form.concatNumber = parseConcatNumber(this.form.concatNumber);
  }

  private handleJoin() {
    (this.$refs.form as ElForm).validate((valid: boolean) => {
      if (valid) {
        joinUser(this.form).then(() => {
          localStorage.setItem('registerFlag', 'true');
          this.$message.success('회원가입이 완료되었습니다.');
          this.$router.push({ name: 'Login' });
        }).catch((error) => {
          this.$message.error('회원가입 요청에 실패했습니다.');
        });
      }
    });
  }

  private handleNiceCheckPlus() {
    (this.$refs.niceForm as HTMLFormElement).action = 'https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb';
    (this.$refs.niceForm as HTMLFormElement).submit();
  }

  private async setUserInfo() {
    this.form.type = getLoginType();
    this.form.code = getLoginCode();
    getInfo(String(this.$route.query.EncodeData)).then((res) => {
      this.form.actualName = res.data.name;
      this.form.birth = res.data.birth;
      this.form.concatNumber = parseConcatNumber(res.data.phone);
      const birth = moment(res.data.birth, 'YYYYMMDD').format('YYYY-MM-DD');
      this.form.birth = birth;
    });
  }
}
